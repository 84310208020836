.processstep_list{display: flex; width: 100%; flex-direction: column;}
.processstep_list li{display: flex; justify-content: space-between; align-items: center; flex-flow: row nowrap; width: 100%; background: #F4F5F8; border-radius: 26px; height: 52px; padding: 0 15px 0 25px; position: relative; margin-bottom: 15px; cursor: pointer;}
.processstep_list_right{flex-basis: 170px; display: inline-flex; justify-content: flex-end;}
.greentick{display: inline-flex; justify-content: center; align-items: center; flex-flow: row nowrap; width: 22px; height: 22px; background: #2DB266 url(../img/svg/tick_white.svg); background-repeat: no-repeat; background-size: 55%; background-position: center; border-radius: 50%; visibility: hidden;}
.edittab{display: inline-flex; justify-content: center; align-items: center; flex-flow: row nowrap; color: #fff; width: 40px; height: 22px; border-radius: 48px; background: #0C444E; visibility: hidden; cursor: pointer;}
.processstep_list_left{display: inline-flex; align-items: center; justify-content: flex-start;}
.processstep_list li:hover{background-color: #E8FFE4;}
.processstep_list li:hover .greentick, .processstep_list li:hover .edittab{visibility: visible;}
.basicIcon{background-image: url(../img/svg/family.svg); width: 18px; height: 18px; display: inline-block; background-repeat: no-repeat; background-size: 100%; background-position: center;}
.protection{background-image: url(../img/svg/protection_black.svg); width: 18px; height: 18px; display: inline-block; background-repeat: no-repeat; background-size: 100%; background-position: center;}
.goal{background-image: url(../img/svg/goal.svg); width: 18px; height: 18px; display: inline-block; background-repeat: no-repeat; background-size: 100%; background-position: center;}
.cashflow{background-image: url(../img/svg/cashflow.svg); width: 18px; height: 18px; display: inline-block; background-repeat: no-repeat; background-size: 100%; background-position: center;}
.rupee{background-image: url(../img/svg/rupee.svg); width: 18px; height: 18px; display: inline-block; background-repeat: no-repeat; background-size: 100%; background-position: center;}
.processstep_list li.active{background-color: #E8FFE4;}
.processstep_list li.active .greentick, .processstep_list li.active .edittab{visibility: visible;}

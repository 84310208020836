@charset "utf-8";
.headerWpr{display: flex; width: 100%; position: relative; border-bottom: 1px solid #EDEFF2; align-items: center; height: 48px; background: #fff;}
.hdrCol{display: flex; justify-content: space-between; align-items: center; flex-flow: row nowrap; position: relative;}
.hdrleft{display: inline-flex; align-items: center;}
.hdrright{display: inline-flex; align-items: center; justify-content: flex-end;}
.finnvoate_logo{background-image: url(../img/finnovatelogo.jpg); width: 32px; height: 28px; background-size: 100%; background-repeat: no-repeat; display: inline-block;}
.infosteps{display: flex; flex-flow: row nowrap;}
.infosteps li{position: relative; margin-right: 6px; cursor: pointer;}
.infosteps li .basicdtlbtn, .infosteps li .protectbtn{display: inline-flex; align-items: center; background-color: #0D6CCA; border-radius: 45px; color:#fff; padding: 0 8px; height: 22px;}
.infosteps li .protectbtn{border: 1px solid #C6CFD7; background-color: #fff; color: #353F51; height: 22px;}
.infosteps li .stepsiconbox{display: inline-flex; align-items: center; justify-content: center;border: 1px solid #C6CFD7; border-radius: 50%; width: 22px; height: 22px;}
.infosteps li .stepsiconbox .stepstext{display: none;}
.infosteps li .stepsiconbox .stepstext:empty{display: none;}
.infosteps li::after{content: ''; width: 8px; height: 1px; background-color: #DDE2E7; position: absolute; right: -8px; top: 50%; transform: translateY(-50%);}
.infosteps li:last-child:after{display: none;}
.groupicon{width: 12px; height: 12px; background-color: #f2f2f2; display: inline-block; border-radius: 50%;}
.basicIcon_white{background-image: url(../img/svg/family_white.svg); width: 18px; height: 18px;
    display: inline-block; background-repeat: no-repeat; background-size: 100%; background-position: center;}
.family_icon{background-image: url(../img/svg/family_gray.svg); width: 18px; height: 18px;
        display: inline-block; background-repeat: no-repeat; background-size: 100%; background-position: center;}
.protection_clr{background-image: url(../img/svg/protection.svg); width: 18px; height: 18px; display: inline-block; background-repeat: no-repeat; background-size: 100%; background-position: center;}
.home_icon{background-image: url(../img/svg/home.svg); width: 18px; height: 18px; display: inline-block; background-repeat: no-repeat; background-size: 100%; background-position: center;}
.protection_gray{background-image: url(../img/svg/protection_gray.svg); width: 18px; height: 18px; display: inline-block; background-repeat: no-repeat; background-size: 100%; background-position: center;}
.goal_clr{background-image: url(../img/svg/goal_clr.svg); width: 18px; height: 18px; display: inline-block; background-repeat: no-repeat; background-size: 100%; background-position: center;}
.goal_blueclr{background-image: url(../img/svg/goal_blue.svg); width: 18px; height: 18px; display: inline-block; background-repeat: no-repeat; background-size: 100%; background-position: center;}
.cashflow_clr{background-image: url(../img/svg/cashflow_clr.svg); width: 18px; height: 18px; display: inline-block; background-repeat: no-repeat; background-size: 100%; background-position: center;}
.risk{background-image: url(../img/svg/risk.svg); width: 18px; height: 18px; display: inline-block; background-repeat: no-repeat; background-size: 90%; background-position: center;}
.lock{background-image: url(../img/svg/lock.svg); width: 18px; height: 18px; display: inline-block; background-repeat: no-repeat; background-size: 100%; background-position: center;}
.rupee_clr{background-image: url(../img/svg/rupee_clr.svg); width: 18px; height: 18px; display: inline-block; background-repeat: no-repeat; background-size: 75%; background-position: center;}
.cashflow_peechclr{background-image: url(../img/svg/cashflow_peechclr.svg); width: 18px; height: 18px; display: inline-block; background-repeat: no-repeat; background-size: 100%; background-position: center;}
.cashflow_orngclr{background-image: url(../img/svg/cashflow_orngclr.svg); width: 18px; height: 18px; display: inline-block; background-repeat: no-repeat; background-size: 100%; background-position: center;}
.edit_icon{background-image: url(../img/svg/edit.svg); width: 18px; height: 18px; display: inline-block; background-repeat: no-repeat; background-size: 100%; background-position: center;}


.infosteps li.active .stepsiconbox .stepIcon, .infosteps li.active_next .stepsiconbox .stepIcon{margin-right: 4px;}
.infosteps li.active .stepsiconbox .stepstext, .infosteps li.active_next .stepsiconbox .stepstext{display: inline-block;}
.infosteps li.active .stepsiconbox .stepstext:empty, .infosteps li.active_next .stepsiconbox .stepstext:empty{display: none;}
.infosteps li.active .stepsiconbox{width: auto; border-radius: 45px; background-color: #0D6CCA; color: #fff;  padding: 0 8px; border: 0;}
.infosteps li.active_next .stepsiconbox{width: auto; border-radius: 45px; background-color: #ffffff; color: #353F51;  padding: 0 8px; border-color: #C6CFD7;}
.infosteps li.active_done .stepsiconbox{ background-color: #0D6CCA; color: #fff; border: 0;}
.infosteps li.active_done::after{background-color: #0D6CCA;}

.infosteps li.active .protection_clr, .infosteps li.active_done .protection_clr{background-image: url(../img/svg/protection_white.svg);}
.infosteps li.active .goal_clr, .infosteps li.active_done .goal_clr{background-image: url(../img/svg/goal_white.svg);}
.infosteps li.active .cashflow_clr, .infosteps li.active_done .cashflow_clr{background-image: url(../img/svg/cashflow_white.svg);}


.hdr_downarrow{position: relative;}
.profileimg{display: inline-flex; width: 30px; height: 30px; border-radius: 50%; background-color: #f2f2f2; vertical-align: middle;}
.notification_icon{ background-image: url(../img/svg/bell.svg); background-repeat: no-repeat; background-size: 100%; width: 20px; height: 20px; display: inline-block; position: relative; vertical-align: middle;}
/* .notification_cricle{background-color: #FF5942; width: 9px; height: 9px; display: inline-flex; justify-content: center; align-items: center; color: #fff; font-size: 11px; border-radius: 50%; position:absolute; right: -3px; top: -3px;} */

.previewtab{display: inline-flex; justify-content: center; align-items: center; flex-flow: row nowrap; cursor: pointer; height: 28px; background: linear-gradient(0deg, #E0D7FF, #E0D7FF), #FFFFFF;
    box-shadow: 0px 1px 15px rgba(44, 44, 45, 0.05); border-radius: 8px; padding: 0 10px;}
.diamondIcon{background-image: url(../img/svg/diamond.svg); width: 14px; height: 14px; display: inline-block; background-repeat: no-repeat; background-size: 100%;}


/* Dashboard Header */
.infoTabs{display: flex; width: 100%; flex-flow: row nowrap;}
.infoTabs li a{padding: 0 20px; font-weight: 500; color: #717C90; position: relative; height: 48px; display: inline-flex; align-items: center; justify-content: center;}
.infoTabs li a.act{font-weight: 600; color: #000000;}
.infoTabs li a.act::after{content: ''; height: 2px; background-color: #0D6CCA; width: 34px; position: absolute; bottom: 0; left: 50%; transform: translateX(-50%);}

/* Dashboard Header */


/* Nav */
.hdrCol .navbar{position: absolute; top: 0; right: 0; padding: 0; outline: none;}
.hdrCol .navbar .navbar-toggler{border: 0; outline: none;}
.hdrCol .navbar-collapse{position: absolute;
    right: 12px;
    top: 35px;
    z-index: 4;
    min-width: 200px;
    background-color: #fff;
    border: 1px solid #e4e4e4;
    border-radius: 6px;}

.hdrCol .navbar-collapse .navbar-nav li{padding: 10px 20px; border-bottom: 1px solid #e4e4e4;}
.hdrCol .navbar-collapse .navbar-nav li:last-child{border-bottom: 0;}

/* Nav */


.profilelist{position: absolute; right: 0; top:37px; min-width: 120px; background-color: #fff; border-radius: 0 0 10px 10px; z-index: 2; border-style: solid; border-width:0 1px 1px 1px; border-color: #f2f2f2; visibility: hidden; transition: all 300ms;}
.profilelist li{display: block; padding: 12px 20px; cursor: pointer;} 
.hdr_downarrow:hover .profilelist{visibility: visible;}
.investment_list{display: flex; justify-content: flex-start; flex-flow: row nowrap; width: 100%;}
.investment_list ul{display: grid; grid-template-columns: repeat(4, 1fr); gap: 24px; width: 100%;}
.investment_list li{display: inline-flex;}
.investment_list li label{position: relative;}
.investment_list li label .invest_outer{display: inline-flex; align-items: center; justify-content: center; flex-direction: column;  width: 139px; height: 132px; border: 1.5px solid #E8EAEC; border-radius: 20px; cursor: none; position: relative;}
.investment_list li label input{display: none;}
.investment_list li label .greentick{ border-radius: 45px; width: 20px; height: 20px; display: inline-flex; justify-content: center; align-items: center; flex-flow: row nowrap; position: absolute; right: 10px; top: 10px; background: #2DB266 url(../img/svg/tick_white.svg); background-repeat: no-repeat; background-size: 50%; background-position: center; display: none;}
.investment_list li label input[type=checkbox]:checked + .greentick{display: inline-block;}
.investment_list li label input[type=checkbox]:checked ~ .invest_outer{border-color: #2DB266; border-width: 2px;}

.invest_list_text{font-size: 15px; font-weight: 500; color: #495262;}
.tabsIcon{display: inline-flex; width: 48px; height: 48px; border-radius: 50%; background-color: #E9EEF5; justify-content: center; align-items: center; flex-flow: row nowrap;}
.mutualfunds_tab .tabsIcon{background-color: #BBDEFB; background-image: url(../img/svg/mutualfund.svg); background-repeat: no-repeat; background-size: 60%; background-position: center;}
.pig_tab .tabsIcon{background-color: #C8E6C9; background-image: url(../img/svg/pig.svg); background-repeat: no-repeat; background-size: 60%; background-position: center;}
.chartbar_tab .tabsIcon{background-color: #FFCCBC; background-image: url(../img/svg/chartbar.svg); background-repeat: no-repeat; background-size: 60%; background-position: center;}
.gold_tab .tabsIcon{background-color: #FFECB3; background-image: url(../img/svg/gold.svg); background-repeat: no-repeat; background-size: 60%; background-position: center;}
.office_tab .tabsIcon{background-color: #FFF9C4; background-image: url(../img/svg/office.svg); background-repeat: no-repeat; background-size: 60%; background-position: center;}
.coins_tab .tabsIcon{background-color: #D7CCC8; background-image: url(../img/svg/coins.svg); background-repeat: no-repeat; background-size: 60%; background-position: center;}
.moneytime_tab .tabsIcon{background-color: #C0E5F6; background-image: url(../img/svg/moneytime.svg); background-repeat: no-repeat; background-size: 60%; background-position: center;}
.plant_tab .tabsIcon{background-color: #E9EEF5; background-image: url(../img/svg/plant.svg); background-repeat: no-repeat; background-size: 60%; background-position: center;}



.dreamcar_tab .tabsIcon{background-image: url(../img/goals/dreamcar.png); background-repeat: no-repeat; background-size: 60%; background-position: center;}
.recurringvacation_tab .tabsIcon{background-image: url(../img/goals/recurringvacation.png); background-repeat: no-repeat; background-size: 60%; background-position: center;}
.dreambike_tab .tabsIcon{background-image: url(../img/goals/dreambike.png); background-repeat: no-repeat; background-size: 60%; background-position: center;}
.education_tab .tabsIcon{background-image: url(../img/goals/education.png); background-repeat: no-repeat; background-size: 60%; background-position: center;}
.house_tab .tabsIcon{background-image: url(../img/goals/house.png); background-repeat: no-repeat; background-size: 60%; background-position: center;}
.customgoal_tab .tabsIcon{ background-image: url(../img/goals/customgoal.png); background-repeat: no-repeat; background-size: 60%; background-position: center;}
.bigvacation_tab .tabsIcon{ background-image: url(../img/goals/bigvacation.png); background-repeat: no-repeat; background-size: 60%; background-position: center;}
.wealth_tab .tabsIcon{ background-image: url(../img/goals/wealthcreation.png); background-repeat: no-repeat; background-size: 60%; background-position: center;}
.bigwedding_tab .tabsIcon{ background-image: url(../img/goals/bigwedding.png); background-repeat: no-repeat; background-size: 60%; background-position: center;}

@media only screen and (max-width: 767px) {
    .investment_list ul{grid-template-columns: repeat(2, 1fr); gap: 20px;}
    .investment_list li label{width: 100%;}
    .investment_list li label .invest_outer{width: 100%; height: 40vw;}
}


.add_goal_list{display: flex; justify-content: flex-start; flex-flow: row nowrap; width: 100%;}
.add_goal_list ul{display: grid; grid-template-columns: repeat(3, 1fr); gap: 10px; width: 100%;}
.add_goal_list li{display: inline-flex;}
.add_goal_list li label{position: relative;}
.add_goal_list li label .invest_outer{display: inline-flex; align-items: center; justify-content: center; flex-direction: column;  width: 139px; height: 132px; border: 1.5px solid #E8EAEC; border-radius: 20px; cursor: pointer; position: relative;}
.add_goal_list li label input{display: none;}